export default {
  name: "ClaimsDetailComponent",
  data() {
    return {
      texts: "",
      dateTableDetail: {},
      oVendor: {},
      oCustomer: {},
      oDestinationWarehouse: {},
      oStatus: {},
      oSalesOrder: {},
      oWarehouse: {},
      aClaimTicketImages:[],
      isMobile: false,
      isTablet: false,
      isFixed: false,
      isCenter: false,
      imgWidthResponive: false,
      rating: 4.5,
      loaded: false,
      imgSelected: "",
      aImages: [],
      isfinalized: null,
    };
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  created() {},
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeMount() {
    this.claimsDetail();
    this.$store.commit("setMenuSidebar", { active: false });
    this.texts = FILE.claimsTexts[this.selectLanguage];
  },
  methods: {
    // manda al detalle del vendedor que se encuentra en el detalle de la disputa
    detailVendor: function(id) {
      this.$router.push(`/admin/vendor-detail/${id}`);
    },

    // manda al detall del comprador que se encuentra en el detallde la disputa
    detailCustomer: function(id) {
      this.$router.push(`/admin/customer-detail/${id}`);
    },
    //abre el dialogo para actualizar el estado de la disputa
    updateState: function() {
      this.$store.commit("setDialogUpdateStatusClaims", {
        active: true,
        arr: this.dateTableDetail,
      });
    },
    onResize() {
      this.isFixed = window.innerWidth > 959;
      this.isMobile = window.innerWidth < 600;
      this.isCenter = window.innerWidth > 600 && window.innerWidth < 960;
      this.isTablet = window.innerWidth > 959 && window.innerWidth < 1264;
    },
    // obtiene el detalle de la disputa seleccionada
    claimsDetail() {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/claim-tickets/${this.$route.params.id}`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {
            // iPageNumber: 1,
          },
        }
      )
        .then((response) => {
          this.dateTableDetail = response.data.results;
          this.oVendor = this.dateTableDetail.oVendor;
          this.oWarehouse = this.dateTableDetail.oVendor.oWarehouse;
          this.oCustomer = this.dateTableDetail.oCustomer;
          this.oCustomerWarehouse =
            this.dateTableDetail.oDestinationWarehouse;
          this.oStatus = this.dateTableDetail.oStatus;
          this.oSalesOrder = this.dateTableDetail.oSalesOrder;
          this.aClaimTicketImages = this.dateTableDetail.aClaimTicketImages
          this.isfinalized =
            this.oStatus.sStatusId ==
            "db2289ae-6222-4785-9587-0276e0c21657";
          this.imgSelected =
            this.aClaimTicketImages.length > 0
              ? this.aClaimTicketImages[0].oImages.lg
              : "";
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },

    // obtienen la imagen seleccionada para ponerla en la imagen principal
    selectedimg(img) {
      this.imgSelected = img.lg;
    },
    // formatea a moneda los valores
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    isCancelOrFinished(id){
      //El primer id es cancelada y el segundo es resuelta
      if (id === "44687fe1-51d9-48d8-8b7c-a9ad2a9819a7" || id === "db2289ae-6222-4785-9587-0276e0c21657") {
        return true
      } else {
        return false        
      }
    }
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.claimsTexts[this.selectLanguage];
        this.claimsDetail();
      }
    },
    refreshTable: function() {
      this.claimsDetail();
    },
  },
};
